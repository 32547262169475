@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans:ital,wght@0,100;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,300;1,400;1,500;1,700;1,800;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

* {
    font-family: 'Open Sans', sans-serif;
}

.hidden {
    display: none;
}
.showing {
    display: unset;
}

.columna-uno {
    display: block;
    background-color: #0001fe;
    color: white;
    grid-column: 1/2;
    width: 100%;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-thumb {
    background-color: slategrey;
    border-radius: 10px;
}

::-webkit-scrollbar-track{
    background-color: darkgrey;
    border-radius: 10px;
}

.link {
    margin-left: auto;
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    margin-top: auto;
    margin-bottom: auto;
}

.page-item {
    list-style: none;
    cursor: pointer;
    padding: 10px 16px;
    font-weight: bold;
    color: #000;
}

.page-link {
    text-decoration: none;
}

.pagination {
    display: flex;
    justify-content: center;
}

.active {
    border: 1px solid #7778fe;
    background-color: #7778fe;
    border-radius: 100px;
    color: #fff;
}